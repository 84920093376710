import React from 'react';
import Helmet from 'react-helmet';

import { Layout, StateRegulationLinks } from '../components/common';

const Index = () => {
  return (
    <>
      <Helmet>
        <title>Rent Control</title>
        <meta
          name="description"
          content="Millions of people rent not knowing the body of laws around it. rentcontrol.io exists to help renters and help them know laws that enable and protect them."
        />
      </Helmet>
      <Layout>
        {/*<section className="hero is-black">
          <div className="hero-body">
            <div className="container content">
              <h1 className="title is-1 has-text-centered has-mb-1">Tools and resources for renters</h1>
              <div className="columns is-centered">
                <div className="column is-3">
                  <Link to="/laws-and-regulations">
                    <figure className="image is-128x128 is-centered has-mv-0">
                      <img className="is-rounded" src={JusticeBook} />
                    </figure>
                    <h3 className="title has-text-centered has-mt-7 has-mb-0">Laws &amp; Regulations</h3>
                  </Link>
                </div>
                <div className="column is-3">
                  <Link to="/lifecycle">
                    <figure className="image is-128x128 is-centered has-mv-0">
                      <img className="is-rounded" src={CityResidential} />
                    </figure>
                    <h3 className="title has-text-centered has-mt-7 has-mb-0">Tenant Lifecycle</h3>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>*/}
        <section className="section">
          <div className="container">
            <h2 className="title is-2 has-text-centered has-mb-1">Find laws by state</h2>
            <StateRegulationLinks />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Index;
